import { LexicalBlock, LexicalBlockType, LexicalComponents } from "./types";
import { getFormattingOptions } from "./utils/get-formatting-options";
import { DEFAULT_LEXICAL_COMPONENTS } from "./constants/default-lexical-components";
import { LexicalTextNode } from "./components/lexical-text-node";

export type LexicalProps = {
  block: LexicalBlock | null | undefined;
  components?: Partial<LexicalComponents>;
};

export function Lexical({ block, ...props }: LexicalProps) {
  const components: LexicalComponents = {
    ...DEFAULT_LEXICAL_COMPONENTS,
    ...props?.components,
  };

  if (!block) {
    return null;
  }

  // Quite often the CMS will return an object with a root property, this makes sure that we can handle that
  if ("root" in block) {
    return <Lexical block={block.root as LexicalBlock} {...props} />;
  }

  if (block.type === LexicalBlockType.lineBreak) {
    return <components.linebreak {...block} />;
  }

  let children: React.ReactNode = null;

  if ("children" in block) {
    children = block.children?.map((child, index) => (
      // Recursively dig into the tree until we reach a block without children
      <Lexical key={index} block={child} {...props} />
    ));
  } else if ("text" in block) {
    children = block.text;
  }

  const formattingOptions = getFormattingOptions(block.format);

  switch (block.type) {
    case LexicalBlockType.root: {
      return <components.root {...block}>{children}</components.root>;
    }
    case LexicalBlockType.quote: {
      return <components.quote {...block}>{children}</components.quote>;
    }
    case LexicalBlockType.heading: {
      return <components.heading {...block}>{children}</components.heading>;
    }
    case LexicalBlockType.paragraph: {
      return (
        <components.paragraph {...block} {...formattingOptions}>
          {children}
        </components.paragraph>
      );
    }
    case LexicalBlockType.autolink:
    case LexicalBlockType.link: {
      return <components.link {...block}>{children}</components.link>;
    }
    case LexicalBlockType.upload: {
      return <components.upload {...block}>{children}</components.upload>;
    }
    case LexicalBlockType.text: {
      return (
        <LexicalTextNode
          block={block}
          components={components}
          formattingOptions={formattingOptions}
        >
          {children}
        </LexicalTextNode>
      );
    }
    case LexicalBlockType.list: {
      const firstChild = block.children[0];
      const indent =
        firstChild && "indent" in firstChild ? firstChild.indent : 0;

      return (
        <components.list {...block} indent={indent}>
          {children}
        </components.list>
      );
    }
    case LexicalBlockType.listItem: {
      return (
        <components.listitem
          {...block}
          isNested={block.children.some(
            (child) => child.type === LexicalBlockType.list,
          )}
        >
          {children}
        </components.listitem>
      );
    }
    default: {
      return (
        <components.unknown {...(block as LexicalBlock)}>
          {children}
        </components.unknown>
      );
    }
  }
}
