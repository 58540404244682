import { ExtendButtonBase } from "@mui/material/ButtonBase";
import MuiIconButton, {
  IconButtonTypeMap,
  type IconButtonProps as MuiIconButtonProps,
} from "@mui/material/IconButton";
import { forwardRef } from "react";

// Make `aria-label` required
export type IconButtonProps = Omit<MuiIconButtonProps, "aria-label"> & {
  "aria-label": string;
};

export const IconButton = forwardRef((props, ref) => {
  return <MuiIconButton {...props} ref={ref} />;
}) as ExtendButtonBase<IconButtonTypeMap<IconButtonProps>>;
