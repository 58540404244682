import { LexicalComponents, LexicalLinkType } from "../types";

export const DEFAULT_LEXICAL_COMPONENTS: LexicalComponents = {
  heading: ({ tag: Tag, children, direction }) => (
    <Tag dir={direction}>{children}</Tag>
  ),
  paragraph: ({ children, direction }) => <p dir={direction}>{children}</p>,
  text: ({ children, isUnderline, isStrikethrough }) => (
    <span
      style={{
        textDecoration: isUnderline ? "underline" : "none",
        textDecorationLine: isStrikethrough ? "line-through" : "none",
      }}
    >
      {children}
    </span>
  ),
  root: ({ children, direction }) => (
    <article dir={direction}>{children}</article>
  ),
  strong: ({ children, isStrikethrough, isUnderline }) => (
    <strong
      style={{
        textDecoration: isUnderline ? "underline" : "none",
        textDecorationLine: isStrikethrough ? "line-through" : "none",
      }}
    >
      {children}
    </strong>
  ),
  em: ({ children, isStrikethrough, isUnderline }) => (
    <em
      style={{
        textDecoration: isUnderline ? "underline" : "none",
        textDecorationLine: isStrikethrough ? "line-through" : "none",
      }}
    >
      {children}
    </em>
  ),
  upload: () => null,
  quote: ({ children, direction }) => (
    <blockquote dir={direction}>{children}</blockquote>
  ),
  list: ({ children, tag: Tag, direction }) => (
    <Tag dir={direction}>{children}</Tag>
  ),
  listitem: ({ children, isNested, value, direction }) => (
    <li
      value={value}
      dir={direction}
      style={{ listStyleType: isNested ? "none" : undefined }}
    >
      {children}
    </li>
  ),
  link: ({ children, fields, direction }) => {
    // Only deal with external links
    if (fields.linkType !== LexicalLinkType.custom) {
      return null;
    }

    return (
      <a
        href={fields.url}
        dir={direction}
        {...(fields.newTab
          ? { target: "_blank", rel: "noopener noreferrer" }
          : {})}
      >
        {children}
      </a>
    );
  },
  unknown: (props) => (
    <>
      UNSUPPORTED CONTENT TYPE:{" "}
      {typeof props?.type === "string" ? props.type : null}
    </>
  ),
  linebreak: () => <br />,
};
