import {
  Lexical as CmsLexical,
  LexicalHeadingTag,
  LexicalLinkType,
  LexicalProps as CmsLexicalProps,
} from "@xenia-libs/cms";
import { Typography } from "../typography";
import { List, ListItem } from "../list";
import { Link } from "../link";
import { Stack } from "../layout";
import { getTypographyStyles } from "./utils/get-typography-styles";
import { listIndentStyles } from "./constants/list-indent-styles";
import { SxProps } from "@mui/material/styles";

export type LexicalProps = Omit<CmsLexicalProps, "components"> & {
  components?: Partial<CmsLexicalProps["components"]>;
  testId?: string;
};

const headingStyles: Record<LexicalHeadingTag, SxProps> = {
  [LexicalHeadingTag.h1]: { mt: "0.4em" },
  [LexicalHeadingTag.h2]: { mt: "0.3em" },
  [LexicalHeadingTag.h3]: { mt: "0.2em" },
};

export function Lexical(props: LexicalProps) {
  return (
    <CmsLexical
      {...props}
      components={{
        root: ({ children, direction }) => (
          <Stack
            gap={1}
            sx={{
              "& > *:first-child": {
                mt: 0,
              },
            }}
            dir={direction}
            data-testid={props.testId}
          >
            {children}
          </Stack>
        ),
        heading: ({ tag, children, direction }) => (
          <Typography
            dir={direction}
            sx={headingStyles[tag]}
            variant={tag}
            data-testid="heading"
          >
            {children}
          </Typography>
        ),
        paragraph: ({ children, direction }) => (
          <Typography dir={direction}>{children}</Typography>
        ),
        strong: ({ children, ...props }) => (
          <Typography
            component="strong"
            sx={{ ...getTypographyStyles(props), fontWeight: 600 }}
          >
            {children}
          </Typography>
        ),
        em: ({ children, ...props }) => (
          <Typography component="em" sx={getTypographyStyles(props)}>
            {children}
          </Typography>
        ),
        quote: ({ children, direction }) => (
          <Typography
            component="blockquote"
            dir={direction}
            sx={(theme) => ({
              borderLeft: "0.25em solid",
              borderLeftColor: theme.palette.grey[200],
              pl: 2,
            })}
          >
            {children}
          </Typography>
        ),
        text: ({ children, ...props }) => (
          <Typography
            component="span"
            sx={{
              ...getTypographyStyles(props),
              fontSize: "inherit",
              lineHeight: "inherit",
              fontFamily: "inherit",
              fontWeight: "inherit",
            }}
          >
            {children}
          </Typography>
        ),
        list: ({ children, tag, start, indent, direction }) => {
          const styles = listIndentStyles[tag];
          // Use module to cycle through the list styles e.g. if we have 3 styles
          // and indent is 4, we want the 1st style so we don't run out of options
          const listStyleType = styles[indent % styles.length];

          return (
            <List
              dir={direction}
              disablePadding
              sx={{ pl: 2, listStyleType }}
              component={tag}
              start={start}
              data-testid="list"
            >
              {children}
            </List>
          );
        },
        listitem: ({ children, value, isNested, direction }) => {
          return (
            <ListItem
              disableGutters
              dir={direction}
              value={value}
              sx={{
                listStyleType: isNested ? "none" : "inherit",
                display: "list-item",
                py: 0.25,
              }}
            >
              {children}
            </ListItem>
          );
        },
        link: ({ children, fields, direction }) => {
          //TODO deal with internal links

          if (fields.linkType !== LexicalLinkType.custom) {
            return null;
          }

          return (
            <Link
              href={fields.url}
              dir={direction}
              data-testid="link"
              {...(fields.newTab
                ? { target: "_blank", rel: "noopener noreferrer" }
                : {})}
            >
              {children}
            </Link>
          );
        },
        ...props.components,
      }}
    />
  );
}
