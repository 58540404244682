import { WithFormatting } from "@xenia-libs/cms";
import { SxProps } from "@mui/material/styles";
import { CSSProperties } from "react";

export function getTypographyStyles(formatting: WithFormatting): SxProps {
  const textDecorationLine: CSSProperties["textDecorationLine"][] = [];

  if (formatting.isStrikethrough) {
    textDecorationLine.push("line-through");
  }

  if (formatting.isUnderline) {
    textDecorationLine.push("underline");
  }

  return {
    textDecorationLine: textDecorationLine.join(" "),
  };
}
