enum IconPacks {
  DEFAULT = "material-symbols-light",
}

function getIcon(iconName: string, iconPack: IconPacks = IconPacks.DEFAULT) {
  return `${iconPack}:${iconName}`;
}

export const allIcons = {
  concierge: getIcon("concierge-outline-rounded"),
  add: getIcon("add-rounded"),
  chevronRight: getIcon("chevron-right-rounded"),
  lock: getIcon("lock-outline"),
  close: getIcon("close-rounded"),
  closeRounded: getIcon("cancel-outline-rounded"),
  menu: getIcon("menu-rounded"),
  refresh: getIcon("refresh-rounded"),
  directions: getIcon("directions-outline-rounded"),
  arrowRight: getIcon("arrow-right-alt-rounded"),
  arrowLeft: getIcon("arrow-left-alt-rounded"),
  search: getIcon("search-rounded"),
  check: getIcon("check-rounded"),
  clock: getIcon("nest-clock-farsight-analog-outline-rounded"),
  calendarMonth: getIcon("calendar-month-rounded"),
  mapPin: getIcon("pin-drop-rounded"),
};

export type AllIcons = keyof typeof allIcons;
