import MuiTextField from "@mui/material/TextField";
import { forwardRef } from "react";
import { TextFieldProps } from "./types/text-field-props";
import MenuItem from "@mui/material/MenuItem";
import { Icon } from "../icon";
import { Typography } from "../typography";

export const TextField = forwardRef<HTMLDivElement, TextFieldProps>(
  ({ children, options, isNative, errorMessage, isLocked, ...props }, ref) => {
    isNative = isNative ?? props.SelectProps?.native;
    const showHelperText = props.helperText || (props.error && errorMessage);

    return (
      <MuiTextField
        {...props}
        disabled={isLocked ?? props.disabled}
        InputProps={{
          endAdornment: isLocked ? (
            <Icon icon="lock" size="1.5em" />
          ) : undefined,
          ...props.InputProps,
        }}
        helperText={
          showHelperText && (
            <>
              {props.error && errorMessage && (
                <>
                  <Typography
                    color="inherit"
                    variant="subtitle1"
                    component="span"
                  >
                    {errorMessage}
                  </Typography>
                  <br />
                </>
              )}
              {!!props.helperText && (
                <Typography component="span" variant="subtitle1">
                  {props.helperText}
                </Typography>
              )}
            </>
          )
        }
        SelectProps={{ native: isNative, ...props.SelectProps }}
        ref={ref}
      >
        {/* Render the options if we are using the select component */}
        {props.select &&
          options?.map(({ menuItemProps, ...option }) => {
            if (isNative) {
              return (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              );
            }

            return (
              <MenuItem key={option.value} {...option} {...menuItemProps}>
                {/* Menu item supports any content being rendered, so allow it but fallback to the label */}
                {menuItemProps?.children || option.label}
              </MenuItem>
            );
          })}
        {/* Allow for rich content to be rendered for the select */}
        {children}
      </MuiTextField>
    );
  },
);
