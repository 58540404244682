import { LexicalFormat } from "../types/lexical-blocks.type";

// Lexical encodes what combination of formatting options are present in a text block
// with a single number. This is a way to encode multiple boolean values and so we
// can use bitwise operations to check if a certain formatting option is present.
// https://github.com/facebook/lexical/blob/64468b565ffb2782285ef1771fd10aabd2380741/packages/lexical/src/LexicalConstants.ts#L39
// https://github.com/payloadcms/payload/blob/0eb7b44735b8385ae5256fcf3b538fae0a11b84d/packages/richtext-lexical/src/field/features/converters/html/converter/converters/text.ts#L11
const IS_BOLD = 1;
const IS_ITALIC = 1 << 1;
const IS_STRIKETHROUGH = 1 << 2;
const IS_UNDERLINE = 1 << 3;

// These are unsupported formatting options, but we can utilise them if/when they are supported
const IS_CODE = 1 << 4;
const IS_SUBSCRIPT = 1 << 5;
const IS_SUPERSCRIPT = 1 << 6;
const IS_HIGHLIGHT = 1 << 7;

export type FormattingOptions = {
  isBold: boolean;
  isItalic: boolean;
  isStrikethrough: boolean;
  isUnderline: boolean;
};

function getFormat(format: LexicalFormat, value: number) {
  return !!(format && format & value);
}

export function getFormattingOptions(format: LexicalFormat): FormattingOptions {
  return {
    isBold: getFormat(format, IS_BOLD),
    isItalic: getFormat(format, IS_ITALIC),
    isStrikethrough: getFormat(format, IS_STRIKETHROUGH),
    isUnderline: getFormat(format, IS_UNDERLINE),
  };
}
