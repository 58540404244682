export enum LexicalDirection {
  ltr = "ltr",
  rtl = "rtl",
}

export enum LexicalBlockType {
  root = "root",
  text = "text",
  heading = "heading",
  paragraph = "paragraph",
  upload = "upload",
  quote = "quote",
  list = "list",
  listItem = "listitem",
  link = "link",
  autolink = "autolink",
  lineBreak = "linebreak",
}

export enum LexicalLinkType {
  custom = "custom",
  internal = "internal",
}

export enum LexicalHeadingTag {
  h1 = "h1",
  h2 = "h2",
  h3 = "h3",
}

type WithBlockType<Type extends LexicalBlockType, T = unknown> = T & {
  type: Type;
};

export type LexicalFormat = "" | number;

export type LexicalRootBlock = WithBlockType<
  LexicalBlockType.root,
  {
    format: LexicalFormat;
    indent: number;
    version: number;
    direction: LexicalDirection;
    children: LexicalBlock[];
  }
>;

export type LexicalLineBreakBlock = WithBlockType<
  LexicalBlockType.lineBreak,
  {
    version: number;
  }
>;

export type LexicalTextBlock =
  | LexicalLineBreakBlock
  | WithBlockType<
      LexicalBlockType.text,
      {
        detail: number;
        format: LexicalFormat;
        mode: "normal"; //TODO what are the options,
        style: ""; //TODO what are the options
        text: string;
        version: number;
      }
    >;

export type LexicalHeadingBlock = WithBlockType<
  LexicalBlockType.heading,
  {
    children: LexicalTextBlock[];
    direction: LexicalDirection;
    format: LexicalFormat;
    indent: number;
    version: number;
    tag: LexicalHeadingTag;
  }
>;

export type LexicalParagraphBlock = WithBlockType<
  LexicalBlockType.paragraph,
  {
    children: LexicalTextBlock[];
    direction: LexicalDirection;
    format: LexicalFormat;
    indent: number;
    version: number;
  }
>;

export type LexicalUploadBlock = WithBlockType<
  LexicalBlockType.upload,
  {
    format: LexicalFormat;
    version: number;
    fields: null; //TODO what are the options
    relationTo: string; // TODO what are the options
    value: {
      id: string;
      filename: string;
      width: number;
      height: number;
      url: string;
      description?: string;
      blurDataUrl?: string | null;
    };
  }
>;

export type LexicalQuoteBlock = WithBlockType<
  LexicalBlockType.quote,
  {
    format: LexicalFormat;
    direction: LexicalDirection;
    version: number;
    children: LexicalBlock[];
  }
>;

export type LexicalListBlock = WithBlockType<
  LexicalBlockType.list,
  {
    format: LexicalFormat;
    direction: LexicalDirection;
    indent: number;
    version: number;
    listType?: "bullet"; //TODO what are the options e.g. bullet
    start: number;
    tag: "ol" | "ul";
    children: LexicalBlock[];
  }
>;

export type LexicalListItemBlock = WithBlockType<
  LexicalBlockType.listItem,
  {
    format: LexicalFormat;
    direction: LexicalDirection;
    version: number;
    indent: number;
    value: number;
    children: LexicalTextBlock[] | LexicalListBlock[];
  }
>;

type WithLinkType<Type extends LexicalLinkType, T = unknown> = T & {
  linkType: Type;
};
export enum LexicalLinkRelationTo {
  articles = "articles",
}

export type LexicalCustomLinkFields = WithLinkType<
  LexicalLinkType.custom,
  {
    url: string;
    newTab: boolean;
  }
>;
export type LexicalInternalLinkFields = WithLinkType<
  LexicalLinkType.internal,
  {
    url: string;
    newTab: boolean;
    doc: {
      value: {
        // TODO this is all the info we need for now, but we should be grabbing the type
        // from the CMS's GraphQL schema and doing a union of the different internal links we
        // can have. Right now, we can just link to other articles.
        id: string;
        title: string;
        slug: string;
      };
      relationTo: LexicalLinkRelationTo;
    };
  }
>;

export type LexicalLinkFields =
  | LexicalInternalLinkFields
  | LexicalCustomLinkFields;

type LinkProps = {
  format: LexicalFormat;
  direction: LexicalDirection;
  children: LexicalTextBlock[];
  indent: number;
  version: number;
  fields: LexicalLinkFields;
};

export type LexicalLinkBlock = WithBlockType<LexicalBlockType.link, LinkProps>;

export type LexicalAutolinkBlock = WithBlockType<
  LexicalBlockType.autolink,
  LinkProps
>;

export type LexicalBlock =
  | LexicalLineBreakBlock
  | LexicalLinkBlock
  | LexicalAutolinkBlock
  | LexicalListItemBlock
  | LexicalListBlock
  | LexicalQuoteBlock
  | LexicalUploadBlock
  | LexicalRootBlock
  | LexicalTextBlock
  | LexicalHeadingBlock
  | LexicalParagraphBlock;
