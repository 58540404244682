import { Fonts } from "../../graphql/generated";

export type FontLinksProps = {
  fontFiles: Fonts["filePaths"] | undefined | null;
  favIcon: string | null | undefined;
  includeLavandaFonts?: boolean;
};

/**
 * Provides multiple `link` tags to hook up the font files and the favicon
 * configured for a brand. This can be injected into the `head` element
 * of the document to enable server rendering of the assets.
 */
export function HeadElements({
  fontFiles,
  favIcon,
  includeLavandaFonts = true,
}: FontLinksProps) {
  return (
    <>
      {Array.from(new Set(fontFiles || []))
        ?.filter((filePath): filePath is string => !!filePath)
        .map((path, index) => (
          <link key={index} href={path} rel="stylesheet" />
        ))}

      {includeLavandaFonts && (
        <>
          <link rel="preconnect" href="https://fonts.googleapis.com" />
          <link
            rel="preconnect"
            href="https://fonts.gstatic.com"
            crossOrigin=""
          />
          <link
            href="https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap"
            rel="stylesheet"
          />
        </>
      )}

      {!!favIcon && <link rel="icon" href={favIcon} />}
    </>
  );
}
