import { Theme } from "@mui/material/styles";
import { SystemStyleObject } from "@mui/system/styleFunctionSx";

export function lineClamp(lines: number): SystemStyleObject<Theme> {
  return {
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: lines,
    overflow: "hidden",
  };
}
