import "iconify-icon";
import { AllIcons, allIcons } from "./all-icons";
import { Box, BoxProps } from "../layout";

export type IconProps = BoxProps & {
  icon: AllIcons;
  size?: string;
};

export function Icon({ icon, size = "1em", ...props }: IconProps) {
  const iconName = allIcons[icon];

  if (!iconName) {
    return null;
  }

  return (
    // Wrap in a Box to get all the MUI goodness
    <Box
      height={size}
      width={size}
      display="inline-flex"
      justifyContent="center"
      alignItems="center"
      {...props}
    >
      {/*
        We are using a web component in React, which is fine but TS moans it's not a
        valid HTML element. We can safely ignore this error. You can do some module
        augmentation to fix this, but the Next's bundler doesn't recognise the
        type defs.
      */}
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/* @ts-ignore */}
      <iconify-icon
        data-testid="iconify-icon"
        icon={iconName}
        height="100%"
        width="100%"
      />
    </Box>
  );
}
