import { PropsWithChildren } from "react";
import { FormattingOptions } from "../utils/get-formatting-options";
import { LexicalComponents, LexicalTextBlock } from "../types";

export type LexicalTextNodeProps = PropsWithChildren<{
  block: LexicalTextBlock;
  components: LexicalComponents;
  formattingOptions: FormattingOptions;
}>;

/**
 * Progressively builds up the text node with the correct formatting options
 * and semantic HTML elements e.g. <strong> and <em>
 */
export function LexicalTextNode({
  block,
  components,
  children,
  formattingOptions,
}: LexicalTextNodeProps) {
  const props = {
    ...block,
    ...formattingOptions,
  };

  let node = <components.text {...props}>{children}</components.text>;

  if (formattingOptions.isBold) {
    node = <components.strong {...props}>{node}</components.strong>;
  }

  if (formattingOptions.isItalic) {
    node = <components.em {...props}>{node}</components.em>;
  }

  return node;
}
